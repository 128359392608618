import React from "react"
import Heading from "./heading.js"
import RedButton from "./redbutton.js"
import "../styles/leadership.css"
import John from "../images/leadership/john.png"
import Alex from "../images/leadership/alex.png"
import Abe from "../images/leadership/abe.jpg"
import Will from "../images/leadership/will.png"
import Nazar from "../images/leadership/nazar.jpg"
import Dario from "../images/leadership/dario.png"
import Kavin from "../images/leadership/kavin.jpg"
import Niall from "../images/leadership/niall.jpg"
import Hassan from "../images/leadership/hassan.png"
import Josh from "../images/leadership/josh.jpg"
import Cade from "../images/leadership/cade.jpg"
import Ben from "../images/leadership/ben.png"
import Matthew from "../images/leadership/matthew.jpg"
import Joey from "../images/leadership/joey_bream.png"
import Bilal from "../images/leadership/bilal.jpg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope, faGlobe } from "@fortawesome/free-solid-svg-icons"
import {
  faInstagram,
  faLinkedin,
  faGithub,
} from "@fortawesome/free-brands-svg-icons"

const Leadership = () => (
  <section className="lead-wrapper">
    <span id="leads"></span>
    <Heading title="LEADERSHIP" size="h1" />
    <div className="exec-grid">
      <div className="box">
        <figure>
          <img src={Matthew} alt="Matthew Simpson" />
          <div className="box-title">
            <h3>Matthew Simpson</h3>
            <h6>Chief Executive Officer</h6>
          </div>
          <figcaption>
            <div className="social-logos">
              <a
                href="https://www.linkedin.com/in/matthew-simpson-ncsu/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faLinkedin} size="2x" />
              </a>
              <a href="mailto:mssimpso@liquidrocketry.com">
                <FontAwesomeIcon icon={faEnvelope} size="2x" />
              </a>
              <a
                href="https://github.com/matthewsimpsonaero"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faGithub} size="2x" />
              </a>
            </div>
          </figcaption>
        </figure>
      </div>
      <div className="box">
        <figure>
          <img src={Alex} alt="Alexander Allen Chief Engineer" />
          <div className="box-title">
            <h3>Alexander Allen</h3>
            <h6>Chief Executive Engineer</h6>
          </div>
          <figcaption>
            <div className="social-logos">
              <a
                href="https://www.linkedin.com/in/alexander-allen/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faLinkedin} size="2x" />
              </a>
              <a href="mailto:arallen@liquidrocketry.com">
                <FontAwesomeIcon icon={faEnvelope} size="2x" />
              </a>
              <a
                href="https://github.com/robocoder99"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faGithub} size="2x" />
              </a>
            </div>
          </figcaption>
        </figure>
      </div>
	<div className="box">
        <figure>
          <img src={Nazar} alt="Nazar Rush Lead of Propulsion" />
          <div className="box-title">
            <h3>Nazar Rush</h3>
            <h6>Propulsion Lead</h6>
          </div>
          <figcaption>
            <div className="social-logos">
              <a href="mailto:nazar@liquidrocketry.com">
                <FontAwesomeIcon icon={faEnvelope} size="2x" />
              </a>
            </div>
          </figcaption>
        </figure>
      </div>
      <div className="box">
        <figure>
          <img src={Kavin} alt="Kavin Govindarajan CFO" />
          <div className="box-title">
            <h3>Kavin Govindarajan</h3>
            <h6>Chief Financial Officer</h6>
          </div>
          <figcaption>
            <div className="social-logos">
              <a
                href="https://kmgovind.github.io/DigitalPortfolio/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faGlobe} size="2x" />
              </a>
              <a href="mailto:kavin@liquidrocketry.com">
                <FontAwesomeIcon icon={faEnvelope} size="2x" />
              </a>
              <a
                href="https://www.instagram.com/km_govind/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faInstagram} size="2x" />
              </a>
              <a
                href="https://github.com/kmgovind/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faGithub} size="2x" />
              </a>
            </div>
          </figcaption>
        </figure>
      </div>
      <div className="box">
        <figure>
          <img src={Ben} alt="Ben Black Structures Lead" />
          <div className="box-title">
            <h3>Ben Black</h3>
            <h6>Structures Lead</h6>
          </div>
          <figcaption>
            <div className="social-logos">
              <a
                href="https://www.linkedin.com/in/benjamin-black/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faLinkedin} size="2x" />
              </a>
              <a href="mailto:bcblack6@liquidrocketry.com">
                <FontAwesomeIcon icon={faEnvelope} size="2x" />
              </a>
            </div>
          </figcaption>
        </figure>
      </div>
      <div className="box">
        <figure>
          <img src={Bilal} alt="Bilal Syed Chief of Staff" />
          <div className="box-title">
            <h3>Bilal Syed</h3>
            <h6>Chief of Staff</h6>
          </div>
          <figcaption>
            <div className="social-logos">
              <a href="mailto:bmsyed@liquidrocketry.com">
                <FontAwesomeIcon icon={faEnvelope} size="2x" />
              </a>
              <a
                href="https://www.linkedin.com/in/bilal-syed-6654b2191/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faLinkedin} size="2x" />
              </a>
            </div>
          </figcaption>
        </figure>
      </div>
    </div>
    <br />
    <RedButton
      link="https://forms.gle/8WUuyYxkBPVbDVbNA"
      text=" Join The Team!"
    />
  </section>
)

export default Leadership
